import { _ } from 'core-js'
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
const app = createApp({
    ...{
        setup(){
            if(_.isEmpty(localStorage.getItem('uuid'))) localStorage.setItem('uuid', uuid.v4());
        },
        data(){
            return {
                db:new Dexie('db'),
                communicationChannel: null,
                serviceWorker:null,
                registration:null
            }
        },
        async created(){
            
            this.db.version(1).stores({
                orders: "++order_id,id,parent_id,status,currency,version,prices_include_tax,date_created,date_modified,discount_total,discount_tax,shipping_total,shipping_tax,cart_tax,total,total_tax,customer_id,order_key,billing,shipping,payment_method,payment_method_title,transaction_id,customer_ip_address,customer_user_agent,created_via,customer_note,date_completed,date_paid,cart_hash,number,meta_data,line_items,tax_lines,shipping_lines,fee_lines,coupon_lines,refunds,date_created_gmt,date_modified_gmt,date_completed_gmt,date_paid_gmt,tailgate_redeemed,_tailgate_redeemed,currency_symbol,_links",
                redeemed: "++order_id,id,parent_id,status,currency,version,prices_include_tax,date_created,date_modified,discount_total,discount_tax,shipping_total,shipping_tax,cart_tax,total,total_tax,customer_id,order_key,billing,shipping,payment_method,payment_method_title,transaction_id,customer_ip_address,customer_user_agent,created_via,customer_note,date_completed,date_paid,cart_hash,number,meta_data,line_items,tax_lines,shipping_lines,fee_lines,coupon_lines,refunds,date_created_gmt,date_modified_gmt,date_completed_gmt,date_paid_gmt,tailgate_redeemed,_tailgate_redeemed,currency_symbol,_links"
            });

            this.registration = new Worker('worker.js');
            // this.registration = await navigator.serviceWorker.register(`sw.js`);
            
            // try{
            //     this.communicationChannel = new BroadcastChannel('communicationChannel');
            //     setInterval(async ()=>{
            //         let req = await fetch('./files/ping.txt')
            //         let res = req.text();

            //     },5000)
            // }
            // catch(err){
            //     if(this.registration.install){
            //         this.communicationChannel =  this.registration.installing;
            //     }
            //     else if(this.registration.waiting){
            //         this.communicationChannel =  this.registration.waiting;
            //     }
            //     else if(this.registration.active){
            //         this.communicationChannel =  this.registration.active;
            //         setInterval(async ()=>{
            //             let req = await fetch('./files/ping.txt')
            //             let res = req.text();

            //         },5000)
            //     }
            // }
           
        },
        async mounted(){
            // setInterval(()=>{
            //     fetch('files/ping.txt')
            // }, 5000);
            
           
        }
    },
    ...App
}).use(store).use(router);
// app._component.data().db.version(1).stores({
//     orders: "++order_id,id,parent_id,status,currency,version,prices_include_tax,date_created,date_modified,discount_total,discount_tax,shipping_total,shipping_tax,cart_tax,total,total_tax,customer_id,order_key,billing,shipping,payment_method,payment_method_title,transaction_id,customer_ip_address,customer_user_agent,created_via,customer_note,date_completed,date_paid,cart_hash,number,meta_data,line_items,tax_lines,shipping_lines,fee_lines,coupon_lines,refunds,date_created_gmt,date_modified_gmt,date_completed_gmt,date_paid_gmt,tailgate_redeemed,_tailgate_redeemed,currency_symbol,_links",
//     redeemed: "++order_id,id",
// });
app.mount('#app')
