<template>
  <div class="home">
      <MDBContainer>
        <MDBRow>
          <MDBCol class=" col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3"></MDBCol>
          <MDBCol class=" col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <transition name="fade">
              <MDBCard class="rounded-0" v-if="ready">
                <MDBCardBody>
                  <MDBCardTitle class="text-center"><img src="@/assets/logo.png"></MDBCardTitle>
                  <MDBCardText>
                    <transition name="fade"><div class="alert alert-danger" v-if="error">{{error}}</div></transition>
                    <form @submit.prevent="submit">
                      <fieldset :disabled="busy">
                        <MDBSpinner class="position-absolute top-50 start-50" v-if="busy"></MDBSpinner>
                        <MDBInput class="mb-3" type="password" label="Password" size="lg" v-model="password" />
                        <MDBBtn type="submit" color="dark" class="rounded-0 text-uppercase">Log In</MDBBtn>
                      </fieldset>
                    </form>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </transition>
          </MDBCol>
          <MDBCol class=" col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3"></MDBCol>
        </MDBRow>
    </MDBContainer>
    
  </div>
</template>

<script>
 import {MDBSpinner, MDBInput , MDBCol, MDBRow, MDBContainer, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn,MDBModal, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter} from 'mdb-vue-ui-kit';
 import { ref } from 'vue';
export default {
  name: 'Home',
  components: {
    MDBSpinner, MDBInput, MDBCol, MDBRow, MDBContainer, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn,MDBModal, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter
  },
  data(){
    return {
      busy:false,
      ready:false,
      password:'',
      error:''
    }
  },
  async mounted(){
    if(this.$route.path=='/'&&!_.isEmpty(localStorage.getItem('token'))){ 
        this.$router.push('/scan'); 
    }
    this.ready = true;
    
  },
  methods:{
    async submit(){
      this.error = '';
      this.busy = true;
      let req = await fetch(`${this.$store.state.api}/verify_password/${(this.password)}`,{
        method:'GET',
        headers:{
          'Authorization':`Basic ZnBhZG1pbjppRHNQdEhFWXhHVmkzT2dhc3NZT3UxR0s=`
        }
      });
      let res = await req.json();
      console.log(res)
      if(res.hasOwnProperty('token')){
        localStorage.setItem('token', res.token);
        localStorage.setItem('appPassword', res.appPassword);
        this.ready = false;
        await Sleep(500);
        this.$router.push('/scan');

      }
      else{
        this.error = 'Incorrect Password';
        this.busy = false;
      }
    }
  }
}
</script>
