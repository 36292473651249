import { createStore } from 'vuex'

export default createStore({
  state: {
    api:'https://eventusa.com/wp-json/v2',
    cameras:_.isEmpty(localStorage.getItem('cameras'))?null:JSON.parse(localStorage.getItem('cameras')),
    camera:_.isEmpty(localStorage.getItem('camera'))?null:JSON.parse(localStorage.getItem('camera')),
    counts:localStorage.getItem('counts')
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
