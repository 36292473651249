<template>
  <router-view/>
</template>

<style lang="scss">
@import '~@/../mdb/scss/index.free.scss';
:root{
  --app-background:#e5e5e5;
}
*{box-sizing:border-box;}
fieldset[disabled]{
  position:relative;
}
fieldset[disabled] > .spinner-border:nth-of-type(1){
  z-index:100;
  margin-left:-1rem;
  margin-top:-1rem;
}
fieldset[disabled]::after{
  content: '';
  position:absolute;
  display:block;
  z-index:99;
  background-color:rgba(#fff, 0.8);
  top: -5px;
  left: -5px;
  right: -5px;
  bottom:-5px;
}
#app{
  background-color:var(--app-background);
  height: 100vh;
  width: 100vw;

  @media (max-width: 992px){
    padding-top: 55px;
  }
  @media (min-width: 992px){
    display:flex;
    align-items:center;
    justify-content:center;
  }
  > div:first-child{
    // max-width: 650px;
    margin-left: auto;
    margin-right: auto;
  }
  &.success{
    background-color:rgba(#00b74a, 0.25);
  }
  &.error{
    background-color:rgba(#f93154, 0.25);
  }
  > div{
    width: 100vw;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
