<template>
  <div class="scan">
      <transition name="fade">
        <MDBContainer v-if="ready">
            <MDBRow class="align-items-center">
              <MDBCol style="background-color: #757575;" class="col-12 col-lg-5 text-light p-5" v-if="$store.state.camera==null">
                <div class="text-center">
                  <MDBIcon icon="camera-retro" size="9x" @click="getCameras"  aria-controls="cameraModal" />
                  <h4 class="text-light">SELECT CAMERA</h4>
                </div>
              </MDBCol>
              <MDBCol class=" col-12 col-lg-5 text-light p-5" v-else>
                <div id="reader" class="border"></div>
              </MDBCol>
              <MDBCol class=" col-12 col-lg-7 mt-5" id="order-box">
                  <transition name="fade">
                    <div v-if="!_.isEmpty(order)">
                      
                      <MDBTable variant="light" bordered class="shadow" sm>
                        <thead class="bg-dark">
                            <tr>
                                <td class="bg-dark text-white text-center" colspan="2">
                                  <h6 class="text-center text-danger">{{error}}</h6>
                                  <h5 class="mb-0">{{order.name}}<br><span style="font-size: 1rem;">{{order.game_attending}}</span></h5>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="order.adult_traditional_tailgate">
                                <td width="160">Adult Traditional Tailgate</td>
                                <td>{{order.adult_traditional_tailgate}}</td>
                            </tr>
                            <tr v-if="order.adult_vip_tailgate">
                                <td width="160">Adult VIP Tailgate</td>
                                <td>{{order.adult_vip_tailgate}}</td>
                            </tr>
                            <tr v-if="order.child_traditional_tailgate">
                                <td width="160">Child Traditional Tailgate</td>
                                <td>{{order.child_traditional_tailgate}}</td>
                            </tr>
                            <tr v-if="order.child_vip_tailgate">
                                <td width="160">Child VIP Tailgate</td>
                                <td>{{order.child_vip_tailgate}}</td>
                            </tr>
                            <tr v-if="order.tour_members">
                                <td width="160">Tour Members</td>
                                <td v-html="order.tour_members"></td>
                            </tr>
                        </tbody>
                      </MDBTable>
                      <div class="text-center"><MDBBtn @click="clearOrder" color="dark" class="rounded-0 text-uppercase btn-block">CLEAR ORDER</MDBBtn></div>
                    </div>
                  </transition>
                  
              </MDBCol>
            </MDBRow>
        </MDBContainer>
      </transition>
      

    <MDBModal
        id="cameraModal"
        tabindex="-1"
        labelledby="cameraModalLabel"
        v-model="cameraModal"
        class="rounded-0"
    >
        <MDBModalHeader>
            <MDBModalTitle id="cameraModalLabel">SELECT CAMERA</MDBModalTitle>
        </MDBModalHeader>
            <MDBModalBody>
                <MDBListGroup>
                    <MDBListGroupItem @click="selectCamera(camera)" v-for="camera in $store.state.cameras" :key="camera.id" class="rounded-0" :active="$store.state.camera!=null&&camera.id == $store.state.camera.id">{{camera.label}}</MDBListGroupItem>
                </MDBListGroup>
            </MDBModalBody>
    </MDBModal>
    <MDBModal
        id="settingsModal"
        tabindex="-1"
        labelledby="settingsModalLabel"
        v-model="settingsModal"
        class="rounded-0"
    >
        <MDBModalHeader>
            <MDBModalTitle id="settingsModalLabel">SETTINGS</MDBModalTitle>
        </MDBModalHeader>
            <MDBModalBody>
                <MDBBtn @click="getCameras(), this.settingsModal=false" color="dark" class="rounded-0 text-uppercase btn-block">SELECT CAMERA</MDBBtn>
                <MDBBtn @click="settingsModal=false; ordersModal=true; pagination.page = 0; $root.db.orders.clear(); $root.db.redeemed.clear(); counts={adult_traditional:0,adult_vip:0,child_traditional:0,child_vip:0}; getOrders()" color="dark" class="rounded-0 text-uppercase btn-block">DOWNLOAD ORDERS</MDBBtn>
                <br><br>
                <h5 class="text-center">Redemption Counts</h5>
                <table class="table table-sm table-striped table-bordered">
                  <tbody>
                    <tr>
                      <th>Adult Traditional</th>
                      <td>{{counts.adult_traditional}}</td>
                    </tr>
                    <tr>
                      <th>Adult VIP</th>
                      <td>{{counts.adult_vip}}</td>
                    </tr>
                    <tr>
                      <th>Child Traditional</th>
                      <td>{{counts.child_traditional}}</td>
                    </tr>
                    <tr>
                      <th>Child VIP</th>
                      <td>{{counts.child_vip}}</td>
                    </tr>
                  </tbody>
                </table>
            </MDBModalBody>
    </MDBModal>
    <MDBModal
        id="ordersModal"
        tabindex="-1"
        labelledby="ordersModalLabel"
        v-model="ordersModal"
        class="rounded-0"
        staticBackdrop
    >
        <MDBModalBody>
          <h3 class="text-center">DOWNLOADING ORDERS</h3>
          <hr class="mt-3 mb-3">
          <div class="text-center mb-1">{{(((pagination.page-1) / pagination.max_pages) * 100)}}%</div>
          <MDBProgress :height="20">
            <MDBProgressBar :value="(((pagination.page-1) / pagination.max_pages) * 100)" striped animated />
          </MDBProgress>
        </MDBModalBody>
    </MDBModal>
    <MDBBtn @click="settingsModal = true"  color="dark" class="position-fixed top-0 end-0 me-0 opacity-0">&nbsp;<br>&nbsp;</MDBBtn>
  </div>
</template>

<script>
 import {MDBProgress, MDBProgressBar , MDBListGroup, MDBListGroupItem, MDBIcon, MDBTable , MDBSpinner, MDBInput , MDBCol, MDBRow, MDBContainer, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn,MDBModal, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter} from 'mdb-vue-ui-kit';
 import { ref } from 'vue';
export default {
  name: 'Scan',
  components: {
    MDBProgress, MDBProgressBar , MDBListGroup, MDBListGroupItem, MDBIcon, MDBTable, MDBSpinner, MDBInput, MDBCol, MDBRow, MDBContainer, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn,MDBModal, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter
  },
  data(){
    return {
        _:_,
      error:'',
      busy:false,
      ready:false,
      html5QrCode:null,
      localStorage:localStorage,
      scanning:false,
      pagination:{
        page:0,
        per_page:100,
        max_pages:20
      },
      order:{},
      counts:{
        adult_traditional:0,
        adult_vip:0,
        child_traditional:0,
        child_vip:0
      },
    }
  },
  watch:{
    settingsModal:async function(newVal){
      if(!newVal) return;
      this.counts={
        adult_traditional:0,
        adult_vip:0,
        child_traditional:0,
        child_vip:0
      };
      let redeemed = await this.$root.db.redeemed.toArray();
      let orders = await this.$root.db.orders.toArray();

      for(let i = 0; i < redeemed.length; i++){
        let o = redeemed[i];
        let adult_traditional_tailgate = (o.meta_data.filter(m=>m.key=='adult_traditional_tailgate')[0]['value']);
        let adult_vip_tailgate = (o.meta_data.filter(m=>m.key=='adult_vip_tailgate')[0]['value']);
        let child_traditional_tailgate = (o.meta_data.filter(m=>m.key=='child_traditional_tailgate')[0]['value']);
        let child_vip_tailgate = (o.meta_data.filter(m=>m.key=='child_vip_tailgate')[0]['value']);
        if(adult_traditional_tailgate=='') adult_traditional_tailgate = 0;
        else adult_traditional_tailgate = parseInt(adult_traditional_tailgate);

        if(adult_vip_tailgate=='') adult_vip_tailgate = 0;
        else adult_vip_tailgate = parseInt(adult_vip_tailgate);
        
        if(child_traditional_tailgate=='') child_traditional_tailgate = 0;
        else child_traditional_tailgate = parseInt(child_traditional_tailgate);
        
        if(child_vip_tailgate=='') child_vip_tailgate = 0;
        else child_vip_tailgate = parseInt(child_vip_tailgate);

        this.counts['adult_traditional'] += adult_vip_tailgate;
        this.counts['adult_vip'] += adult_vip_tailgate;
        this.counts['child_traditional'] += child_traditional_tailgate;
        this.counts['child_vip'] += child_vip_tailgate;



        // this.counts.adult_traditional = 0;


        // console.log(o)
        // let matchingOrder = (await this.$root.db.orders.where('id').equals(o.id).toArray())[0];
        // console.log(matchingOrder)
      }
    }
  },
  setup() {
      const cameraModal = ref(false);
      const settingsModal = ref(false);
      const ordersModal = ref(false);
      return {
        cameraModal,
        settingsModal,
        ordersModal
      };
    },
  async mounted(){
    // console.log(await this.$root.db.orders.where('id').equals(97807));
    
    if(this.$root.communicationChannel instanceof BroadcastChannel){

    }
    else{
      
    }
    // this.$root.communicationChannel.onmessage = e=>{
    //   // let order = _.first(this.$root.db.orders.where('id').equals(e.data.order_id).toArray());
    //   // console.log('order redeemed: ', order)
    //   console.log('order redeemed', order);
    // }
    if(localStorage.getItem('counts')==null) localStorage.setItem('counts', JSON.stringify({adult_traditional_tailgate:0,adult_vip_tailgate:0,child_traditional_tailgate:0,child_vip_tailgate:0}))
    this.$store.state.counts = JSON.parse(localStorage.getItem('counts'));
    this.ready = true;
    if(this.$route.path!='/'&&_.isEmpty(localStorage.getItem('token'))){ 
        this.$router.push('/'); 
    }
    else{
      await Sleep(150);
      
      this.scanning = true;
      if(!_.isEmpty(this.$store.state.camera)) this.startCamera();
    }
  },
  methods:{
    async clearOrder(){
      this.order = {};
      this.scanning = true;
      this.error = '';
      document.querySelector('#app').classList.remove('success')
      document.querySelector('#app').classList.remove('error')
    },
    async getOrders(){
      this.pagination.page++;
      let req = await fetch(`https://eventusa.com/wp-json/wc/v3/orders?page=${this.pagination.page}&per_page=${this.pagination.per_page}`, {
        method:'GET',
        headers:{
          Authorization:`Basic ${btoa(`fpadmin:${localStorage.getItem('token')}`)}`
        }
      });
      let res = await req.json();
      await this.$root.db.orders.bulkAdd(res);
      if(this.pagination.page < this.pagination.max_pages) this.getOrders();
      else{
        this.ordersModal = false;
        this.pagination = {
          page:0,
          per_page:100,
          max_pages:10
        }
      }
    },
    async openSettings(){},
    async getCameras(e){
      this.$store.state.cameras = await Html5Qrcode.getCameras();
      localStorage.setItem('cameras', JSON.stringify(this.$store.state.cameras));
      this.cameraModal = true;
    },
    async selectCamera(camera){
        if(this.$store.state.camera != null) await this.html5QrcodeScanner.stop();
        this.$store.state.camera = null;
        localStorage.setItem('camera', JSON.stringify(camera));
        this.$store.state.camera = camera;
        await Sleep(10);
        this.scanning = true;
        this.startCamera();
        
    }, 
    async startCamera(){
        this.html5QrcodeScanner=(new Html5QrcodeScanner("reader", {fps:10,qrbox:200}, false));
        let reader = document.querySelector('#reader');
        let size = 200;
       
        this.html5QrcodeScanner = new Html5Qrcode("reader");
        this.html5QrcodeScanner.start(this.$store.state.camera.id, {fps:10, qrbox:200}, 
            async (decodedText, decodedResult)=>{
              if(this.scanning&&_.isEmpty(this.order)&&/order_id/.test(decodedText)){
                this.scanning = false;
                let response = JSON.parse(decodedText);
                let order =  (await this.$root.db.orders.where('id').equals(response.order_id).toArray());
                if(!_.isEmpty(order)){
                  order =  _.first(await this.$root.db.orders.where('id').equals(response.order_id).toArray());
                  this.order = {
                    id:order.id,
                    game_attending:_.filter(order.meta_data, m=>m.key=='game_attending')[0]['value'],
                    order_id:order.order_id,
                    name:order.line_items[0].name,
                    tour_members:'',
                    adult_traditional_tailgate:_.filter(order.meta_data, m=>m.key=='adult_traditional_tailgate')[0]['value'],
                    adult_vip_tailgate:_.filter(order.meta_data, m=>m.key=='adult_vip_tailgate')[0]['value'],
                    child_traditional_tailgate:_.filter(order.meta_data, m=>m.key=='child_traditional_tailgate')[0]['value'],
                    child_vip_tailgate:_.filter(order.meta_data, m=>m.key=='child_vip_tailgate')[0]['value'],
                    tailgate_redeemed:_.filter(order.meta_data, m=>m.key=='tailgate_redeemed')[0]['value'],
                  };
                  if(_.filter(order.line_items[0].meta_data, m=>m.key=='Tour Members').length > 0){
                    this.order.tour_members = _.filter(order.line_items[0].meta_data, m=>m.key=='Tour Members')[0]['value'];
                    this.order.tour_members = this.order.tour_members.split('&nbsp;&nbsp;&nbsp;&nbsp;&middot; ').filter(m=>{
                      return !_.isEmpty(m.trim());
                    }).join('<hr class="mt-0 mb-0">');
                  }
                  if(this.order.tailgate_redeemed!="1"){
                    document.querySelector('#app').classList.add('success');
                    
                    let id = order.order_id;
                    this.$root.registration.postMessage({...response, ...{appPassword: localStorage.getItem('appPassword'),token: localStorage.getItem('token')}});
                    
                    delete order.order_id;
                  }
                  else{
                    
                    document.querySelector('#app').classList.add('error');
                    this.error = 'Tailgate has already been redeemed';
                  }
                }
                else{
                  try{
                    let req = await fetch(`https://eventusa.com/wp-json/wc/v3/orders/${response.order_id}`, {
                      method:'GET',
                      headers:{
                        Authorization:`Basic ${btoa(`fpadmin:${localStorage.getItem('token')}`)}`
                      }
                    });
                    let res = await req.json();
                    order = res;
                    this.order = {
                      id:order.id,
                      game_attending:_.filter(order.meta_data, m=>m.key=='game_attending')[0]['value'],
                      order_id:order.order_id,
                      name:order.line_items[0].name,
                      tour_members:'',
                      adult_traditional_tailgate:_.filter(order.meta_data, m=>m.key=='adult_traditional_tailgate')[0]['value'],
                      adult_vip_tailgate:_.filter(order.meta_data, m=>m.key=='adult_vip_tailgate')[0]['value'],
                      child_traditional_tailgate:_.filter(order.meta_data, m=>m.key=='child_traditional_tailgate')[0]['value'],
                      child_vip_tailgate:_.filter(order.meta_data, m=>m.key=='child_vip_tailgate')[0]['value'],
                      tailgate_redeemed:_.filter(order.meta_data, m=>m.key=='tailgate_redeemed')[0]['value'],
                    };
                    if(_.filter(order.line_items[0].meta_data, m=>m.key=='Tour Members').length > 0){
                      this.order.tour_members = _.filter(order.line_items[0].meta_data, m=>m.key=='Tour Members')[0]['value'];
                      this.order.tour_members = this.order.tour_members.split('&nbsp;&nbsp;&nbsp;&nbsp;&middot; ').filter(m=>{
                        return !_.isEmpty(m.trim());
                      }).join('<hr class="mt-0 mb-0">');
                    }
                    if(this.order.tailgate_redeemed!="1"){
                      document.querySelector('#app').classList.add('success');
                      
                      let id = order.order_id;
                      this.$root.registration.postMessage({...response, ...{appPassword: localStorage.getItem('appPassword'),token: localStorage.getItem('token')}});
                      
                      delete order.order_id;
                    }
                    else{
                      
                      document.querySelector('#app').classList.add('error');
                      this.error = 'Tailgate has already been redeemed';
                    }
                    
                  }
                  catch(err){
                    this.order= {id:response.order_id};
                    document.querySelector('#app').classList.add('error');
                    this.error = 'Order does not exist on this device';
                  }
                }

                
                
              }
            },
            (error)=>{
              // this.scanning = true;
            }
        );
    }
  }
}
</script>
<style lang="scss">
    #reader{
      
      margin-left: auto;
      margin-right: auto;
      box-shadow: 10px 10px 0 #757575,
      -10px 10px 0 #757575,
      10px -10px 0 #757575,
      -10px -10px 0 #757575;
      display:flex;
      align-items:center;
      justify-content:center;
      // max-width: 300px;
      // max-height: 300px;
      overflow: hidden;
      @media (min-width: 768px){
        // max-width: 400px;
        // max-height: 300px;
      }
    }
    #order-box{
      &:empty{
        position:relative;
        &::before{
          content: 'SCAN QR CODE';
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          transform:translateY(-50%);
          text-align:center;
          font-size:1.7rem;
        }
      }
    }
</style>